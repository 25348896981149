// src/components/Navbar.js
import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="./login">Login</Link>
        </li>
        <li>
          <Link to="./signup">Sign Up</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
